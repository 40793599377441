<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="角色组：" prop="group_id">
            <el-select v-model="form.group_id" placeholder="请选择">
              <el-option
                v-for="(item, index) in CUSTOMER_GRUOP_OPTIONS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名：" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="昵称：" prop="nickname">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱：" prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="头像：" prop="avatar">
            <uploads v-model="form.avatar"></uploads>
          </el-form-item>
          <el-form-item label="等级：" prop="level">
            <el-input v-model="form.level"></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="gender">
            <el-input v-model="form.gender"></el-input>
          </el-form-item>
          <el-form-item label="生日：" prop="birthday">
            <el-date-picker
              v-model="form.birthday"
              type="date"
              value-format="YY-MM-DD"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="格言：" prop="bio">
            <el-input v-model="form.bio"></el-input>
          </el-form-item>
          <el-form-item label="余额：" prop="money">
            <el-input v-model="form.money"></el-input>
          </el-form-item>
          <el-form-item label="积分：" prop="score">
            <el-input v-model="form.score"></el-input>
          </el-form-item>
          <el-form-item label="连续登录天数：" prop="successions">
            <el-input v-model="form.successions"></el-input>
          </el-form-item>
          <el-form-item label="最大连续登录天数：" prop="maxsuccessions">
            <el-input v-model="form.maxsuccessions"></el-input>
          </el-form-item>
          <el-form-item label="上次登录时间：" prop="prevtime">
            <el-date-picker
              v-model="form.prevtime"
              type="datetime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="登录时间：" prop="logintime">
            <el-date-picker
              v-model="form.logintime"
              type="datetime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="登录IP：" prop="loginip">
            <el-input v-model="form.loginip"></el-input>
          </el-form-item>
          <el-form-item label="失败次数：" prop="loginfailure">
            <el-input v-model="form.loginfailure"></el-input>
          </el-form-item>
          <el-form-item label="加入IP：" prop="joinip">
            <el-input v-model="form.joinip"></el-input>
          </el-form-item>
          <el-form-item label="加入时间：" prop="jointime">
            <el-date-picker
              v-model="form.jointime"
              type="datetime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="状态：">
            <el-radio-group v-model="form.status">
              <el-radio label="normal">正常</el-radio>
              <el-radio label="hidden">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { CUSTOMER_GRUOP_OPTIONS } from "@/helpers/constants";
import { editCustomer } from "@/apis/user/customer";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { partenRules } from "@/helpers/validator";

const passwordValidator = (rule, value, callback) => {
  console.log(value);
  if (!value) {
    callback();
  }
  if (!partenRules.password[0].test(value)) {
    callback(new Error(partenRules.password[1]));
  } else {
    callback();
  }
};
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      CUSTOMER_GRUOP_OPTIONS: CUSTOMER_GRUOP_OPTIONS,
      initForm: {},
      form: {},
      rules: {
        nickname: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        password: [{ validator: passwordValidator, trigger: "blur" }],
      },
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.logintime = new Date(Number(this.form.logintime) * 1000);
        this.form.jointime = new Date(Number(this.form.jointime) * 1000);
        this.form.prevtime = new Date(Number(this.form.prevtime) * 1000);
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const promise = editCustomer;
        // const promise = this.mode === "edit" ? editCustomer : addAdmin;

        promise(this.form).then(({ code, data }) => {
          if (code === 0 && data["__token__"]) {
            promise(this.form, data["__token__"]).then(({ code, msg }) => {
              if (code === 1) {
                this.close();
                this.$emit("refresh");
                msgSuc("操作成功！");
              } else {
                msgErr(msg);
              }
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
